<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        After completing Part A of this experiment, a student determines the slope of their
        calibration graph of absorbance versus
        <chemical-latex content="[FeSCN^{2+}]" />
        to be
        <stemble-latex content="$5160\,\text{M}^{-1}.$" />
        In Part B, a student combines
        <stemble-latex content="$2.00\,\text{mL}$" />
        of a
        <stemble-latex content="$0.00200\,\text{M}$" />
        solution of
        <chemical-latex content="KSCN" />
        with
        <stemble-latex content="$5.00\,\text{mL}$" />
        of a
        <stemble-latex content="$0.00200\,\text{M}$" />
        solution of
        <chemical-latex content="Fe(NO3)3" />
        and
        <stemble-latex content="$3.00\,\text{mL}$" />
        of water. The following equilibrium is established:
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="Fe^{3+}(aq) + SCN^-(aq) <=> FeSCN^{2+}(aq)" />
      </p>

      <p class="mb-2">
        The absorbance of the equilibrium solution is measured to be
        <stemble-latex content="$0.221.$" />
        What is the equilibrium constant of the reaction?
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{Equilibrium constant}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemMcMPL3Q11',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
};
</script>
